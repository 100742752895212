export const dynamicSort = (property) => {
  const descending = property[0] === "-";
  const actualProperty = descending ? property.slice(1) : property;

  return (a, b) => {
    const aValue = a[actualProperty];
    const bValue = b[actualProperty];

    if (aValue < bValue) return descending ? 1 : -1;
    if (aValue > bValue) return descending ? -1 : 1;
    return 0;
  };
};

export const convertEpochToHoursAndMinutes = (timeDifference) => {
  const seconds = Math.floor(timeDifference / 1000);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60).toLocaleString("en-US", {
    minimumIntegerDigits: 2
  });
  const remainingSeconds = (seconds % 60).toLocaleString("en-US", {
    minimumIntegerDigits: 2
  });

  return { hours, minutes, seconds: remainingSeconds };
};
