import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import routes from "./routes";

export function beforeEach(to, from, next) {
  if (to.matched.some((record) => record.meta.authorisedOnly)) {
    store.getters["userAuthenticated"] ? next() : next("/unauthenticated");
  } else {
    next();
  }
}

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => beforeEach(to, from, next));

export default router;
