export default {
  SET_BRAND_SELECTED(state, payload) {
    state.brandSelected = payload;
  },
  SET_BRANDS(state, payload) {
    state.brands = payload;
  },
  SET_DISCOUNT_CODE(state, payload) {
    state.discountCode = payload;
  },
  SET_ERROR_MESSAGE(state, payload) {
    state.errorMessage = payload;
  },
  SET_USER_TOKEN(state, payload) {
    state.userToken = payload;
  },
  SET_EXPIRES_AT(state, payload) {
    state.expiresAt = payload;
  },
  SET_CAMPAIGN(state, payload) {
    state.campaign = payload;
  },
  SET_ORGANISATION(state, payload) {
    state.organisation = payload;
  }
};
