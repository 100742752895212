import axios from "axios";
import { dynamicSort } from "@/utilities";

export default {
  setUserToken({ commit }, payload) {
    if (payload === "loading" || payload === "error") {
      localStorage.removeItem("userToken");
      commit("SET_USER_TOKEN", payload);
    } else {
      localStorage.setItem("userToken", payload);
      commit("SET_USER_TOKEN", payload);
    }
  },

  setBrandSelected({ commit }, payload) {
    commit("SET_BRAND_SELECTED", payload);
  },

  setBrands({ commit }, payload) {
    commit("SET_BRANDS", payload);
  },

  setErrorMessage({ commit }, payload) {
    commit("SET_ERROR_MESSAGE", payload);
  },

  setDiscountCode({ commit }, payload) {
    commit("SET_DISCOUNT_CODE", payload);
  },

  async resetDiscountState({ getters, dispatch }) {
    try {
      const discountCode = await getters.discountCode;
      await dispatch("deleteDiscountCode", discountCode);
      dispatch("setDiscountCode", null);
      dispatch("setBrandSelected", null);
    } catch (error) {
      console.error("Error resetting discount state", error);
      dispatch("setDiscountCode", null);
      dispatch("setBrandSelected", null);
    }
  },

  async fetchBrands({ commit }) {
    try {
      await commit("SET_BRANDS", "loading");
      const brandsApiUrl = process.env.VUE_APP_BRANDS_API_URL;
      const config = {
        method: "get",
        url: brandsApiUrl,
        headers: {
          "Content-Type": "application/json"
        }
      };
      const response = await axios(config);
      if (response.status >= 400) {
        throw new Error("Could not fetch brands");
      }
      const brands = response.data;
      if (brands.length) {
        const sortedBrands = brands.sort(dynamicSort("name"));
        await commit("SET_BRANDS", sortedBrands);
      } else {
        throw new Error("No brands found");
      }
    } catch (error) {
      console.log("Error fetching brands", error);
      commit("SET_BRANDS", "error");
      commit("SET_ERROR_MESSAGE", error.message);
    }
  },
  // eslint-disable-next-line
  async deleteDiscountCode({}, voucherCode) {
    try {
      const endpoint = `${process.env.VUE_APP_MW_API_URL}/voucher/${voucherCode}`;
      const config = {
        method: "delete",
        url: endpoint,
        headers: {
          "x-api-key": process.env.VUE_APP_MW_API_KEY,
          "Content-Type": "application/json"
        }
      };
      const response = await axios(config);
      return response;
    } catch (error) {
      console.error("Error deleting discount code", error);
      return error;
    }
  },

  async fetchDiscountCode({ commit, dispatch, getters }, fetchAttempts = 1) {
    await commit("SET_DISCOUNT_CODE", "loading");
    const brandSelected = getters.brandSelected;
    const brandSelectedId = brandSelected.id;
    try {
      const response = await getEmployeeDiscountInfo();

      const { discountCode, expiresAt, campaign, organisation } = {
        discountCode: response?.data?.voucher_code,
        expiresAt: response?.data?.expires_at,
        campaign: response?.data?.campaign,
        organisation: response?.data?.organisation
      };

      if (!discountCode) {
        throw new Error("no vouchers available, try again later");
      }

      commit("SET_DISCOUNT_CODE", discountCode);
      commit("SET_EXPIRES_AT", expiresAt);
      commit("SET_CAMPAIGN", campaign);
      commit("SET_ORGANISATION", organisation);
    } catch (error) {
      console.error("Error occurred fetching discount code", error);
      if (error?.response?.status === 409) {
        const errorData = error?.response?.data?.error;
        const voucherDetails = errorData?.details;
        const voucherBrand = voucherDetails?.brand;
        const voucherCode = voucherDetails?.voucher_code;
        const expiresAt = voucherDetails?.expires_at;
        const campaign = voucherDetails?.campaign;
        const organisation = voucherDetails?.organisation;

        if (voucherBrand === brandSelectedId) {
          commit("SET_DISCOUNT_CODE", voucherCode);
          commit("SET_EXPIRES_AT", expiresAt);
          commit("SET_CAMPAIGN", campaign);
          commit("SET_ORGANISATION", organisation);
        } else {
          const deleteResponse = await dispatch(
            "deleteDiscountCode",
            voucherCode
          );
          if (deleteResponse?.status === 200) {
            if (fetchAttempts <= 5) {
              await dispatch("fetchDiscountCode", (fetchAttempts += 1));
            } else {
              await commit("SET_DISCOUNT_CODE", "error");
              await commit(
                "SET_ERROR_MESSAGE",
                "Voucher system unavailable, try again later"
              );
            }
          } else {
            commit("SET_DISCOUNT_CODE", "error");
            commit(
              "SET_ERROR_MESSAGE",
              "No vouchers available, try again in 24 hours"
            );
          }
        }
      } else {
        commit("SET_DISCOUNT_CODE", "error");
        const errorData = error?.response?.data?.error;
        const errorDataMessage =
          errorData?.message === "Invalid JWT token"
            ? "Invalid authentication, log in again"
            : errorData?.message;
        const errorMessage = errorDataMessage || error.message;
        commit("SET_ERROR_MESSAGE", errorMessage);
      }
    }

    async function getEmployeeDiscountInfo() {
      const userToken = getters.userToken;
      const data = JSON.stringify({
        token: userToken,
        brand: brandSelectedId
      });
      const endpoint = `${process.env.VUE_APP_MW_API_URL}/voucher`;
      const config = {
        method: "post",
        url: endpoint,
        headers: {
          "x-api-key": process.env.VUE_APP_MW_API_KEY,
          "Content-Type": "application/json"
        },
        data
      };

      const response = await axios(config);
      return response;
    }
  }
};
