import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userToken: localStorage.getItem("userToken") || null,
    discountCode: null,
    brandSelected: null,
    brands: null,
    errorMessage: null,
    expiresAt: null,
    campaign: null,
    organisation: null
  },
  getters,
  mutations,
  actions,
  modules: {}
});
