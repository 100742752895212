export default [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue")
  },
  {
    path: "/brand-select",
    name: "Brand Select",
    meta: {
      authorisedOnly: true
    },
    component: () =>
      import(/* webpackChunkName: "BrandSelect" */ "../views/BrandSelect.vue")
  },
  {
    path: "/discount-code",
    name: "Discount code",
    meta: {
      authorisedOnly: true
    },
    component: () =>
      import(/* webpackChunkName: "DiscountCode" */ "../views/DiscountCode.vue")
  },
  {
    path: "/unauthenticated",
    name: "Unauthenticated",
    component: () =>
      import(
        /* webpackChunkName: "Unauthenticated" */ "../views/Unauthenticated.vue"
      )
  },
  {
    path: "/:catchAll(.*)",
    name: "404 - not found",
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ "../views/PageNotFound.vue")
  }
];
