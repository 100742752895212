export default {
  brands: (state) => state.brands,
  brandSelected: (state) => state.brandSelected,
  userToken: (state) => state.userToken,
  userAuthenticated: (state) => !!state.userToken,
  errorMessage: (state) => state.errorMessage,
  discountCode: (state) => state.discountCode,
  expiresAt: (state) => state.expiresAt,
  campaign: (state) => state.campaign,
  organisation: (state) => state.organisation
};
